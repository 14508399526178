import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import classes from "../LandingSection1/LandingSection1.module.css";
import LandingCard from "../LandingCard/LandingCard";
import target from "../../assets/images/target.png"
import vision from "../../assets/images/shared-vision.png"

export default function LandingSection1() {
  return (
    <Container fluid className={`${classes.container}`}>
      <Row className="w-100 px-5" >
      <h1 className={`mx-5 ${classes.heading}`}>
      01 / WHO are we?
      </h1>
      <div className={`${classes.para_div}`} >
      <p className={`px-5 mx-5 pt-5 fs-5 ${classes.para_text}`}>We are a team! A team of 30 currently and growing daily. We ideate and build digitaly elegant products for our international clients, always having the end customer in the center of the digital experience.</p>
      </div>
      </Row>
      <Row className={`pt-5 mt-5 ${classes.row} `}>
        <Col lg={{span: 2, offset: 0}} sm={6} md={6} xs={8} className={`${classes.col1}`}>
          <Card style={{width: '14rem', height: '17rem'}} className={`${classes.background} `}>
            <Card.Body>
              <Card.Title className={`text-center ${classes.card_title}`}>
              MISSON
              </Card.Title>
              <div className={`${classes.div123}`}>
              <Card.Img  src={target} className={`${classes.card_img}`}/>
              </div>
                  <Card.Text className={`pt-1 text-center ${classes.card_text}`}>We are dedicated to always deliver at the best quality by optimising client’s requirements, new technology and users’ needs.</Card.Text>
             

            </Card.Body>
          </Card>
        </Col>


        <Col lg={{span: 2, offset: 1}} sm={6} md={6} xs={8} className={`${classes.col1}`}>
        <Card style={{width: '14rem', height: '17rem'}} className={`${classes.background} `}>
            <Card.Body>
            <Card.Title className={`text-center ${classes.card_title}`}>
            VISION
              </Card.Title> 
              <div className={`${classes.div123}`}>
              <Card.Img  src={vision} className={`${classes.card_img}`}/>
              </div>
                  <Card.Text className={`text-center ${classes.card_text}`}>To be the Europe’s best company that delivers innovation from idea to realisation.
                  </Card.Text>

            </Card.Body>
          </Card>
        
        </Col>
      </Row>

    </Container>
  );
}
