import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LandingCareer from "./components/LandingCareerHero/LandingCareerHero";
import LandingPage from "./pages/LandingPage/LandingPage";
import LandingAbout from "./pages/LandingAbout/LandingAbout";

function App() {
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/career" element={<LandingCareer />} />
        <Route path="about" element={<LandingAbout />} />
      </Routes>
    </Router>
  );
}

export default App;
