import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "../LandingFooter/LandingFooter.module.css";
import { FaFacebook } from "react-icons/fa";
import { FaSlack } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logo from "../../assets/images/Rlogo.png"

export default function LandingFooter({ isAbout, isLanding }) {
 
  if (isLanding) {
    return (
      <div className={`${classes.container}`}>
        <Container className={`${classes.cont_main}pt-5`}>
          <Row className={` ${classes.row_main } mt-lg-4 pt-lg-5`}>
          
            <Col className={`${classes.col1} `} md={3} xs={12} lg={{span: 3, offset:1}}>
              <img className={`${classes.img} pb-4`} src={logo} />
            </Col>
  
            <Col lg={{span:3, offset: 1}} md={4} xs={12} sm={6} className={`pt-4 ${classes.col2}`}>
              <ul>
                <li className={`"text-dark ${classes.footer_list}`}>
                <a href="">About Re-thing</a> </li>
                <li className={`"text-dark ${classes.footer_list}`}>
                  <a href="">Discover our library</a>
                </li>
                <li className={`"text-dark ${classes.footer_list}`}>
                  <a href="">Questions and answers</a>
                </li>
                
              </ul>
            </Col>
            <Col lg={3} xs={12} md={4} className={`pt-4 ${classes.col3}`}>
              <ul>
      
                <li className={`"text-dark ${classes.footer_list}`}>
                  <a href="">Terms and Conditions</a>
                </li>
                <li className={`"text-dark ${classes.footer_list}`}>
                  <a href="">Privacy Policy</a>
                </li>
               
              </ul>
            </Col>
                     </Row>
        <div className={`${classes.hrline_fix}`}>
        <hr className={`${classes.hrline}`}></hr>
          </div>
        <div>
          <Row className={`mb-1 ${classes.row_second}`}>
          <Col lg={{span: 3}} className={`${classes.col5}`}>
              <p className="text-dark fw-bold pt-3 ps-lg-5 fs-6">
                RE-thing copyright  &copy; {new Date().getFullYear()}
              </p>
            </Col>
            <Col lg={{span:1, offset:2}}>
            </Col>
            <Col className={`ps-5 ${classes.col4}`} lg={{span:3}}>
              <i className="text-dark ml-4 fab fa-2x pe-2">
                <FaFacebook />
              </i>
              <i className="text-dark ml-4 fab fa-2x pe-2">
                <FaInstagram />
              </i>
              <i className="text-dark ml-4 fab fa-2x pe-2">
                <FaSlack />
              </i>
            </Col>

            
          </Row>
          </div>
        </Container>
      </div>
    );
  }
}
