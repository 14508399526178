import React from "react";
import { Card, Col, Container, Row, Stack } from "react-bootstrap";
import classes from "../LandingSectionTry/LandingSectionTry.module.css";
import LandingCard from "../LandingCard/LandingCard";
import data from "../../assets/images/data.png"
import bulb from "../../assets/images/bulb.png"
import web from "../../assets/images/web.png"

export default function LandingSection1() {
  return (
    <Container fluid className={`${classes.container}`}>
      
      <h1 className={`ps-5 ms-5 ${classes.heading}`}>
      02 / WHAT we do?
      </h1>
      
      <div className={`pt-3 mx-5 px-5 ${classes.div1234}`}>
        
        <ul>
          <li className={`${classes.list} fs-5`} >
          There is no size that fits all. 
          </li>
          <li className={`${classes.list} fs-5`} >
          There is no size that fits all. We want to understand what problem your company is faced with. 
          </li>
          <li className={`${classes.list} fs-5`}>
          That is how we tailor the solution and combine services needed to build your digital product.
          </li>
    
        </ul>
          </div>
      <Row className={`pt-5 mt-5 ${classes.row}`}>


      <Col className={`pt-5 ${classes.col1}`} lg={{span: 3}} md={4} sm={4} xs={8}>
        <Card style={{width: '14rem', height: '13rem'}} className={`${classes.card} border rounded`}>
        <div className={`${classes.divq}`}>
          <Card.Img src={web} className={`${classes.img123}`}/>
          </div>
        <Card.Body className="mt-4 pt-5">
              <Card.Title className={`text-center ${classes.card_title}`}>
              DESIGN
              </Card.Title>
             
                  <Card.Text className={`text-center ${classes.card_text}`}>UI/UX Web Design Brand Identity
                  </Card.Text>

            </Card.Body>
          </Card>
        </Col>


        <Col className={`pt-5 ${classes.col1}`}lg={{span: 3}} md={4} sm={4} xs={8}>
        <Card style={{width: '14rem', height: '13rem'}} className={`${classes.card} border rounded-5`}>
        <div className={`${classes.divq}`}>
          <Card.Img src={data} className={`${classes.img123}`}/>
          </div>
        <Card.Body className="mt-4 pt-5">
              <Card.Title className={`text-center ${classes.card_title}`}>
              DEVELOP
              </Card.Title>
             
                  <Card.Text className={`text-center ${classes.card_text}`}>Prototyping Web Development App Development
                  </Card.Text>

            </Card.Body>
          </Card>
        </Col>


        <Col className={`pt-5 ${classes.col1}`} lg={{span:3}} md={4} sm={4} xs={8} >
       
        
        <Card style={{width: '14rem', height: '13rem'}} className={`${classes.card} border rounded-5`}>
          <div className={`${classes.divq}`}>
          <Card.Img src={bulb} className={`${classes.img123}`}/>
          </div>
        <Card.Body className="mt-4 pt-5">
          
              <Card.Title className={`text-center ${classes.card_title}`}>
              GROW
              </Card.Title>

             
                  <Card.Text className={`text-center ${classes.card_text}`}>Strategy and GoToMarket Building your Martech Stack User behavior analysis
                  </Card.Text>
                  

            </Card.Body>
          </Card>
         
        </Col>


      </Row>
     

    </Container>
  );
}
