import React from "react";
import LandingCareerHero from "../../components/LandingCareerHero/LandingCareerHero";
import LandingNavbar from "../../components/LandingNavbar/LandingNavbar";
import LandingJoin from "../../components/LandingJoin/LandingJoin";
import classes from "../LandingAbout/LandingAbout.module.css";
import LandingFooter from "../../components/LandingFooter/LandingFooter";

export default function LandingAbout() {
  return (
    <div className={`${classes.container}`}>
      <LandingNavbar />
      <LandingJoin />
      <LandingCareerHero />
      <LandingFooter isAbout />
    </div>
  );
}
