import React from "react";
import {useRef} from "react"
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import classes from "../LandingNavbar/LandingNavbar.module.css";
import mkd from "../../assets/images/mkd.png";
import usa from "../../assets/images/usa.png";
import boat from "../../assets/images/boat.png";
import logo from "../../assets/images/re.png";
import { Link } from "react-scroll/modules";
import { FaWindows } from "react-icons/fa";
import smooth from "react-scroll/modules/mixins/smooth";



export default function LandingNavbar() {
 

  return (
    <Navbar fixed="top" expand="lg" className={`${classes.navbar}`}>
      <Container>
        <Navbar.Brand href="/" className="text-warning">
          <span className="pe-2 pb-1 ">
            <img
              src={logo}
              width="40"
              height="26"
              className="d-inline-block align-top"
            />
          </span>
         
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          className={`justify-content-around pe-5 me-5 ${classes.links}`}
          id="basic-navbar-nav"
        >
          <Nav className={`"d-flex align-items-center ${classes.nav1}`}>
            <Nav.Link className="text-dark " href="/">
              
            </Nav.Link>
          <Link  className={`pe-3 ${classes.links}`} to="about" spy={true} smooth={true} offset={-100} duration={100}  a href="#about">About
          </Link> 
          <Link  className={`pe-3 ${classes.links}`}  activeClass="active" to="services" offset={-100} duration={100} a href="#">Services
          </Link>
          <Link className={`pe-3 ${classes.links}`}  activeClass="active" to="process" spy={true} smooth={true} offset={-100} duration={100} a href="#">Process
          </Link>
{/*             
            <Nav.Link className="text-dark  " href="#contact">
              Services
            </Nav.Link>
            <Nav.Link className="text-dark" href="#products">
              Process
            </Nav.Link> */}
            {/* <Nav.Link className="text-white fw-light" href="/#link">
              Links
            </Nav.Link> */}
            {/* <Nav.Link>
              <span className="border border-white pb-1 "></span>
            </Nav.Link>
            <Nav.Link className={`${classes.link1}`}>
              <span className={`${classes.link2}`}>
                <img src={mkd} />
                <small className={`text-white ${classes.small}`}>
                  &nbsp;Мк
                </small>
              </span>
            </Nav.Link> */}
           
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
