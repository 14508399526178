import React from "react";
import classes from "../LandingHero/LandingHero.module.css";
import truck_landing from "../../assets/images/trucky.jpg";
import { Card, Col, Container, Row } from "react-bootstrap";
import iconplay from "../../assets/images/iconplay.png";
import cloud1 from "../../assets/images/coud1.png";
import { FaApple } from "react-icons/fa"; 
import { FaGoogle } from "react-icons/fa";
import logo from "../../assets/images/Rlogo.png";
import circle from "../../assets/images/circle.png"
import relevant from "../../assets/images/relevant.png"
import logo1 from "../../assets/images/Rething-logo2.png"


export default function LandingHero() {
  return (
    <Container fluid className={` mt-5 pt-5 ${classes.container}`}>
     <Row className={`pt-5 mt-5 ${classes.row123}`}>
       <Col lg={{span: 4, offset: 1}} md={6} sm={12} xs={12} className={`${classes.col1} pt-5`}>
         <div className={`${classes.div}`}>
    
        <img src={logo1} className={`${classes.logo1}`}/>
       </div>
       </Col>

       <Col md={6} lg={6} className={`pe-5 ${classes.col2}`}>
         <span>
         <h1 className={`${classes.heading}`}>We</h1>
         <h1 className={`${classes.heading}`}>make</h1>
         <h1 className={`${classes.heading}`}>your business</h1>

         <h1 className={`${classes.heading}`}>digitaly relevant</h1>


         </span>
       </Col>
     </Row>
    </Container>
  );
}
