import React from "react";
import classes from "../LandingJoin/LandingJoin.module.css";

export default function LandingJoin() {
  return (
    <div className={` pt-5 mt-5  ${classes.container}`}>
      <h1 className="pt-2 mt-5 text-center">Join Us</h1>
      <hr className={`${classes.hrline}`} />
    </div>
  );
}
