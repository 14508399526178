import React from "react";
import LandingCarousel from "../../components/LandingCarousel/LandingCarousel";
import LandingContact from "../../components/LandingContact/LandingContact";
import LandingFooter from "../../components/LandingFooter/LandingFooter";
import LandingHero from "../../components/LandingHero/LandingHero";
import LandingNavbar from "../../components/LandingNavbar/LandingNavbar";
import LandingSection1 from "../../components/LandingSection1/LandingSection1";
import LandingSectionTry from "../../components/LandingSectionTry/LandingSectionTry";
import LandingSectionTry2 from "../../components/LandingSectionTry2/LandingSectionTry2";
import LandingImages from "../../components/LandingImages/LandingImages";
import classes from "../LandingPage/LandingPage.module.css";

export default function LandingPage() {
  return (
      <div className={`${classes.main_div}`}>
      <LandingNavbar />
      <LandingHero />
      <div id="about">
      <LandingSection1 />
      </div>
      <div id="services">
      <LandingSectionTry/>
      </div>
      <div id="process">
      <LandingSectionTry2/>
      </div>
     <LandingFooter isLanding />
    </div>
  );
}
