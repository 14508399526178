import React from "react";
import classes from "../LandingCareerHero/LandingCareerHero.module.css";
import hero_image from "../../../src/assets/images/moon.jpg";
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";
import arrow2 from "../../assets/images/arrow2.png";

export default function LandingCareerHero() {
  return (
    <div
      className={`py-5 mt-5 ${classes.div123}`}
      style={{ backgroundImage: `url(${hero_image})` }}
    >
      <Container className="py-5 my-4">
        <Row className={`justify-content-between`}>
          <Col lg={5} className={`${classes.col1} pt-5 `}>
            <Accordion className="pt-5 accordion_btn">
              <Accordion.Item eventKey="0" className={`${classes.item1}`}>
                <Accordion.Header className={`${classes.heading}`}>
                  Front End Developer
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className={`${classes.item1}`}>
                <Accordion.Header className="">
                  Front End Developer
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className={`${classes.item1}`}>
                <Accordion.Header className="bg-dark">
                  Front End Developer
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col lg={5} className={`pt-5 justify-content-center`}>
            <Form>
              <div>
                <input
                  className={`border border-warning border-2 rounded  fw-lighter fst-italic ${classes.input2}`}
                  type="text"
                  value="Your Email"
                />
                <Form className="pt-4 ">
                  <input
                    className={`border border-warning border-2 rounded mb-5 fw-lighter fst-italic ${classes.input3}`}
                    type="textarea"
                    value="Your Message"
                  />
                  <Button variant="outline-warning" size="lg" className="">
                    Click Here
                    <img src={arrow2} />
                  </Button>
                </Form>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
