import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import classes from "../LandingSectionTry2/LandingSectionTry2.module.css";
import LandingCard from "../LandingCard/LandingCard";
import target from "../../assets/images/target.png"
import infographic from "../../assets/images/infographic-01.png"
import infographic1 from "../../assets/images/infographic.png"

export default function LandingSection1() {
  return (
    <Container fluid className={`${classes.container}`}>
      
      <h1 className={`ps-5 ms-5 pt-5 ${classes.heading}`}>
      03 / HOW we do it?
      </h1>

        <Col lg={{span: 6, offset: 3}} className={`pt-5 mt-5 text-center ${classes.col1}`}>
          <p className={` fs-5 ${classes.para_text}`}>We apply intelligent processes to reach your goals with maximum efficiency. By doing so we build the futureproof digital product you need, and we help you launch it</p>
          <img  src={infographic} className={`w-100 mb-5 ${classes.img}`}/>
        </Col>

    </Container>
  );
}
